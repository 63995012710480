<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <LeagueRewardsTable />
    </div>
  </div>
</template>

<script>
export default {
  components: {
    LeagueRewardsTable: () => import("@/components/Tables/LeagueRewardsTable"),
  },
};
</script>

<style lang="scss" scoped>
.md-layout {
  position: relative;
  z-index: 2;
}
</style>
